import React, { useState } from "react";
import axios from "axios";
import "./Chatbot.css";
import { API_URL } from "../../api";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const handleSend = async () => {
    if (!userInput.trim()) return;

    // Add user's message to the chat history
    setChatHistory((prev) => [...prev, { sender: "user", text: userInput }]);

    try {
      // Make a request to the backend
      const response = await axios.post(`${API_URL}/query/`, {
        query: userInput,
      });

      // Add bot's response to the chat history
      setChatHistory((prev) => [
        ...prev,
        { sender: "bot", text: response.data.response },
      ]);
    } catch (error) {
      console.error("Error fetching chatbot response:", error);

      // Add an error message to the chat history
      setChatHistory((prev) => [
        ...prev,
        { sender: "bot", text: "Sorry, something went wrong. Please try again later." },
      ]);
    }

    // Clear the input field
    setUserInput("");
  };

  return (
    <div className="chatbot-container">
      {/* Chatbot Button */}
      {!isOpen && (
        <button className="chatbot-toggle" onClick={() => setIsOpen(true)}>
          💬
        </button>
      )}

      {/* Chat Window */}
      {isOpen && (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <h3>Chat with Us</h3>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              ✖
            </button>
          </div>
          <div className="chatbot-messages">
            {chatHistory.map((msg, index) => (
              <div
                key={index}
                className={`chat-bubble ${
                  msg.sender === "user" ? "user-bubble" : "bot-bubble"
                }`}
              >
                {msg.text}
              </div>
            ))}
          </div>
          <div className="chatbot-input">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Type your message..."
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
